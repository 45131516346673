<script lang="ts" setup>
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import TextCheckOptions from '@/components/Jobs/CopilotActivation/TextCheckOptions.vue';
import { computed, ref } from 'vue';

const copilotActivationService = new CopilotActivationService();

defineProps<{
  isDisabled: boolean;
}>();

const isDisableActiveOutreachDialogOpen = ref(false);

const isActiveOutreachEnabled = computed<boolean>(
  () => copilotActivationService.activeOutreachEnabled === true,
);

const setActiveOutreachEnabled = (selected: boolean, override: boolean = false) => {
  if (!override && copilotActivationService.originalActiveOutreachEnabled && !selected) {
    isDisableActiveOutreachDialogOpen.value = true;
  } else {
    copilotActivationService.activeOutreachEnabled = selected;
  }
};

const handleCancel = () => {
  isDisableActiveOutreachDialogOpen.value = false;
};

const handleSwitch = () => {
  isDisableActiveOutreachDialogOpen.value = false;
  setActiveOutreachEnabled(false, true);
};
</script>

<template>
  <div id="copilot-outreach" :class="{ 'pointer-events-none opacity-75': isDisabled }">
    <div class="copilot-outreach-title">Who should Copilot source for this job?</div>
    <div class="flex flex-row gap-3">
      <TextCheckOptions
        :is-selected="isActiveOutreachEnabled"
        badge-content="BETA"
        class="flex-1"
        @click="setActiveOutreachEnabled(true)"
      >
        <template v-slot:title>Active job seekers + passive candidates</template>
        Source from active job seekers and qualified passive talent in the FactoryFix network
      </TextCheckOptions>
      <TextCheckOptions
        :is-selected="!isActiveOutreachEnabled"
        class="flex-1"
        @click="setActiveOutreachEnabled(false)"
      >
        <template v-slot:title>Active job seekers only</template>
        Source only from candidates actively searching for a new job
      </TextCheckOptions>
    </div>
  </div>

  <v-dialog
    :model-value="isDisableActiveOutreachDialogOpen"
    max-width="600px"
    height="226px"
    persistent
  >
    <div class="rounded-lg bg-white p-6">
      <img
        class="float-right mt-1 cursor-pointer"
        src="@/assets/svg/close-black.svg"
        alt="close"
        width="20"
        height="20"
        @click="handleCancel"
      />
      <p class="mb-2 font-serif text-2xl font-black text-copilot">Are you sure?</p>
      <p class="mb-12 font-sans font-normal text-shade-800">
        Saving this change will
        <span class="font-bold text-shade-900">stop all ongoing candidate outreach.</span> Copilot
        will no longer contact or respond to passive candidates for this job.
      </p>
      <!-- Actions -->
      <div class="flex items-center justify-end">
        <v-btn
          :ripple="false"
          class="modal-button-text mr-4"
          variant="flat"
          @click.prevent="handleCancel"
          >Cancel</v-btn
        >
        <v-btn
          :ripple="false"
          class="modal-button-primary"
          variant="flat"
          @click.prevent="handleSwitch"
        >
          Switch to active-only sourcing
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>
<style lang="postcss" scoped>
.copilot-outreach-title {
  @apply mb-4 text-lg font-extrabold;
}
</style>
