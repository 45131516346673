<script setup lang="ts">
import { HireType, PayRatePeriod } from '@factoryfixinc/ats-interfaces';
import { computed, nextTick, ref, watch } from 'vue';
import { useDisplay } from 'vuetify';
import {
  HireTypeItems,
  PayRatePeriodItems,
  ShiftHours,
  ShiftItems,
} from '@/core/jobs/copilot-activation/local-job-data.type';
import { UnitedStatesStates } from '@/core/jobs/copilot-activation/utils/state.utils';
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import ChevronIcon from '@/assets/svg/conversations/ChevronIcon.svg?component';
import FormSection from './FormSection.vue';
import CompanyInfoForm from './CompanyInfoForm.vue';
import MeService from '@/core/shared/me/me.service';
import {
  isAValidShift,
  isRequired,
  isValidMaxPayRate,
  isValidMaxSalaryPayRate,
  isValidPostalCode5,
  positiveOrZero,
} from '@/utils/forms';
import MessageTemplate from '@/components/Shared/Input/Template/MessageTemplate.vue';
import type { VTextField } from 'vuetify/components';

const meService = new MeService();
const copilotActivationService = new CopilotActivationService();
const display = useDisplay();

defineProps<{
  isDisabled: boolean;
}>();

const usingDisplayJobTitle = ref(copilotActivationService.displayTitle);
const payRateMinInput = ref<VTextField | null>(null);
const payRateMaxInput = ref<VTextField | null>(null);
const payRateSalaryMinInput = ref<VTextField | null>(null);
const payRateSalaryMaxInput = ref<VTextField | null>(null);

const isDesktop = computed(() => display.mdAndUp.value);
const companyName = computed(() => meService.employer?.name ?? '');

const isDisplayingShiftOptions = computed(() => {
  let shouldDisplay = false;
  if (copilotActivationService.hireType) {
    shouldDisplay = [HireType.FULL_TIME, HireType.PART_TIME].includes(
      copilotActivationService.hireType,
    );
  }
  return shouldDisplay;
});

const isDisplayingShiftTimes = computed(() => copilotActivationService.shift === 0);
const originalJobTitle = computed(() => copilotActivationService.originalJobTitle ?? '');
const originalHireType = computed(() => copilotActivationService.originalHireType);
const originalShift = computed(() => copilotActivationService.originalShift);

// Pay Rate Validation
const payRateMin = computed(() => copilotActivationService.payRateMin);
const payRateSalaryMin = computed(() => copilotActivationService.payRateSalaryMin);

watch(originalJobTitle, (value) => {
  copilotActivationService.displayTitle = value ?? '';
});
watch(originalHireType, (value) => {
  copilotActivationService.hireType = value ?? null;
});
watch(originalShift, (value) => {
  copilotActivationService.shift = value ?? null;
});

// Pay Rate Validation
watch(payRateMin, () => {
  const hasPayRateMaxValueSet =
    payRateMaxInput.value?.dirty || !isNaN(Number(copilotActivationService.payRateMax));
  if (payRateMinInput.value && hasPayRateMaxValueSet) {
    nextTick(() => {
      if (payRateMaxInput.value && payRateMaxInput.value.dirty) {
        payRateMaxInput.value.validate();
      }
    });
  }
});

watch(payRateSalaryMin, () => {
  const hasPayRateSalaryMaxValueSet =
    payRateSalaryMaxInput.value?.dirty || !isNaN(Number(copilotActivationService.payRateSalaryMax));
  if (payRateSalaryMinInput.value && hasPayRateSalaryMaxValueSet) {
    nextTick(() => {
      if (payRateSalaryMaxInput.value && payRateSalaryMaxInput.value.dirty) {
        payRateSalaryMaxInput.value.validate();
      }
    });
  }
});

async function handleDisplayTitlePlausibleUpdate(focused: boolean) {
  if (!focused) {
    //the user stoped typing or editing the job title, check if we need to update the job title id
    if (copilotActivationService.displayTitle !== usingDisplayJobTitle.value) {
      const jobTitle = await copilotActivationService.fetchRelatedJobTitleForDisplayTitle(
        `${copilotActivationService.displayTitle ?? ''}`,
      );

      if (jobTitle) {
        copilotActivationService.jobTitleId = jobTitle.id;
      }

      usingDisplayJobTitle.value = copilotActivationService.displayTitle;
    }
  }
}
</script>

<template>
  <FormSection
    id="job-details"
    title="Describe the role"
    subtitle="Share essential job details"
    :add-divider="false"
  >
    <!-- JOB TITLE -->
    <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Job title*</p>
    <v-text-field
      v-model="copilotActivationService.displayTitle"
      placeholder="Job title"
      variant="outlined"
      density="compact"
      autofocus
      validate-on="submit"
      :disabled="isDisabled"
      :rules="[isRequired]"
      @update:focused="handleDisplayTitlePlausibleUpdate"
    >
      <template #message="{ message }">
        <MessageTemplate :message="message" />
      </template>
    </v-text-field>

    <!-- HIRE TYPE | SHIFT -->
    <div class="flex flex-wrap">
      <!-- HIRE TYPE -->
      <div :class="{ 'w-1/2 pr-[5px]': isDesktop, 'w-full': !isDesktop }">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Hire type*</p>
        <v-combobox
          v-model:model-value="copilotActivationService.hireType"
          :items="HireTypeItems"
          item-title="value"
          item-value="value"
          variant="outlined"
          density="compact"
          placeholder="Select hire type"
          :menu-icon="ChevronIcon"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          :rules="[isRequired]"
          :disabled="isDisabled"
          validate-on="submit"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-combobox>
      </div>
      <!-- SHIFT -->
      <div
        v-if="isDisplayingShiftOptions"
        :class="{ 'w-1/2 pl-[5px]': isDesktop, 'w-full': !isDesktop }"
      >
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Shift*</p>
        <v-autocomplete
          v-model:model-value="copilotActivationService.shift"
          :items="ShiftItems"
          item-title="text"
          item-value="value"
          variant="outlined"
          density="compact"
          placeholder="Select a shift"
          :menu-icon="ChevronIcon"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          :rules="[isAValidShift]"
          :disabled="isDisabled"
          validate-on="submit"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-autocomplete>
      </div>
      <!-- SHIFT START|END TIMES -->
      <!-- @TODO: Check that time makes sense with each other -->
      <div v-if="isDisplayingShiftTimes" class="w-full">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">
          Shift/ Job Time*
        </p>
        <div class="flex">
          <div class="w-1/2 pr-[5px]">
            <v-autocomplete
              v-model:model-value="copilotActivationService.startTime"
              :items="ShiftHours"
              item-title="value"
              item-value="value"
              variant="outlined"
              density="compact"
              placeholder="Start time"
              :menu-icon="ChevronIcon"
              :return-object="false"
              :menu-props="{ maxWidth: '536px' }"
              :rules="[isRequired]"
              :disabled="isDisabled"
              validate-on="submit"
            >
              <template #message="{ message }">
                <MessageTemplate :message="message" />
              </template>
            </v-autocomplete>
          </div>
          <div class="w-1/2 pl-[5px]">
            <v-autocomplete
              v-model:model-value="copilotActivationService.endTime"
              :items="ShiftHours"
              item-title="value"
              item-value="value"
              variant="outlined"
              density="compact"
              placeholder="End time"
              :menu-icon="ChevronIcon"
              :return-object="false"
              :menu-props="{ maxWidth: '536px' }"
              :rules="[isRequired]"
              :disabled="isDisabled"
              validate-on="submit"
            >
              <template #message="{ message }">
                <MessageTemplate :message="message" />
              </template>
            </v-autocomplete>
          </div>
        </div>
      </div>
    </div>

    <!-- PAY RANGE -->
    <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Pay range*</p>
    <div class="flex">
      <!-- Hourly -->
      <template v-if="copilotActivationService.payRatePeriod === PayRatePeriod.HOURLY">
        <div class="max-w-[120px] pr-[5px]">
          <v-text-field
            v-model="copilotActivationService.payRateMin"
            ref="payRateMinInput"
            placeholder="Min"
            variant="outlined"
            density="compact"
            type="number"
            validate-on="submit"
            :rules="[positiveOrZero]"
            :disabled="isDisabled"
          >
            <template #message="{ message }">
              <MessageTemplate :message="message" />
            </template>
          </v-text-field>
        </div>
        <p class="pt-2">-</p>
        <div class="max-w-[120px] px-[5px]">
          <v-text-field
            v-model="copilotActivationService.payRateMax"
            ref="payRateMaxInput"
            placeholder="Max"
            variant="outlined"
            density="compact"
            type="number"
            validate-on="submit"
            :rules="[
              positiveOrZero,
              isValidMaxPayRate(
                copilotActivationService.payRateMin,
                copilotActivationService.payRateMax,
              ),
            ]"
            :disabled="isDisabled"
          >
            <template #message="{ message }">
              <MessageTemplate :message="message" />
            </template>
          </v-text-field>
        </div>
      </template>

      <!-- Annual -->
      <template v-else>
        <div class="max-w-[120px] pr-[5px]">
          <v-text-field
            v-model="copilotActivationService.payRateSalaryMin"
            ref="payRateSalaryMinInput"
            placeholder="Min"
            variant="outlined"
            density="compact"
            type="number"
            validate-on="submit"
            :rules="[positiveOrZero]"
            :disabled="isDisabled"
          >
            <template #message="{ message }">
              <MessageTemplate :message="message" />
            </template>
          </v-text-field>
        </div>
        <p class="pt-2">-</p>
        <div class="max-w-[120px] px-[5px]">
          <v-text-field
            v-model="copilotActivationService.payRateSalaryMax"
            ref="payRateSalaryMaxInput"
            placeholder="Max"
            variant="outlined"
            density="compact"
            type="number"
            validate-on="submit"
            :rules="[
              positiveOrZero,
              isValidMaxSalaryPayRate(
                copilotActivationService.payRateSalaryMin,
                copilotActivationService.payRateSalaryMax,
              ),
            ]"
            :disabled="isDisabled"
          >
            <template #message="{ message }">
              <MessageTemplate :message="message" />
            </template>
          </v-text-field>
        </div>
      </template>

      <div class="max-w-[200px] flex-grow pl-[5px]">
        <v-autocomplete
          v-model:model-value="copilotActivationService.payRatePeriod"
          :items="PayRatePeriodItems"
          item-title="text"
          item-value="value"
          variant="outlined"
          density="compact"
          placeholder="Frequency"
          :menu-icon="ChevronIcon"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          :rules="[isRequired]"
          :disabled="isDisabled"
          validate-on="submit"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-autocomplete>
      </div>
    </div>

    <!-- ADDRESS -->
    <div class="flex flex-wrap">
      <!-- Street -->
      <div :class="{ 'w-1/2 pr-[5px]': isDesktop, 'w-full': !isDesktop }">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">
          Street address*
        </p>
        <v-text-field
          v-model="copilotActivationService.street1"
          placeholder="Street address"
          variant="outlined"
          density="compact"
          validate-on="submit"
          :rules="[isRequired]"
          :disabled="isDisabled"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-text-field>
      </div>

      <!-- City -->
      <div :class="{ 'w-1/2 pl-[5px]': isDesktop, 'w-full': !isDesktop }">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">City*</p>
        <v-text-field
          v-model="copilotActivationService.city"
          placeholder="City"
          variant="outlined"
          density="compact"
          validate-on="submit"
          :rules="[isRequired]"
          :disabled="isDisabled"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-text-field>
      </div>

      <!-- State -->
      <div :class="{ 'w-1/2 pr-[5px]': isDesktop, 'w-full': !isDesktop }">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">State*</p>
        <v-autocomplete
          v-model:model-value="copilotActivationService.state"
          :items="UnitedStatesStates"
          item-title="name"
          item-value="abbreviation"
          variant="outlined"
          density="compact"
          placeholder="Select state"
          :menu-icon="ChevronIcon"
          :return-object="false"
          :menu-props="{ maxWidth: '536px' }"
          :rules="[isRequired]"
          :disabled="isDisabled"
          validate-on="submit"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-autocomplete>
      </div>

      <!-- Zip -->
      <div :class="{ 'w-1/2 pl-[5px]': isDesktop, 'w-full': !isDesktop }">
        <p class="mb-0.5 font-sans text-xs font-bold leading-[18px] text-shade-880">Zip*</p>
        <v-text-field
          v-model="copilotActivationService.postalCode"
          placeholder="Zip"
          variant="outlined"
          density="compact"
          validate-on="submit"
          :rules="[isRequired, isValidPostalCode5]"
          :disabled="isDisabled"
        >
          <template #message="{ message }">
            <MessageTemplate :message="message" />
          </template>
        </v-text-field>
      </div>

      <!-- Company Information -->
      <div class="w-full">
        <p class="mb-2 font-sans text-xs font-bold leading-[18px] text-shade-880">Company name</p>
        <CompanyInfoForm
          v-model="copilotActivationService.anonymous"
          :company-name="companyName"
          :disabled="isDisabled"
        />
      </div>
    </div>
  </FormSection>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-combobox__selection {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}

.v-input :deep(.v-field.v-field--error),
.v-input--horizontal :deep(.v-field.v-field--error) {
  .v-field__outline {
    @apply text-critical-500;
  }
}
</style>
