<script lang="ts" setup>
import { computed } from 'vue';
import FFTextArea from '@/components/Shared/Input/TextInputs/TextArea.vue';

const props = defineProps<{
  title: string;
  placeholder: string;
  modelValue: string | null;
}>();

defineEmits<{
  'update:modelValue': [value: string];
}>();

const titleText = computed(() => props.title);
const placeholderText = computed(() => props.placeholder);
</script>

<template>
  <div>
    <span class="font-sans text-sm font-bold leading-[18px] text-shade-880">
      {{ titleText }}
    </span>
    <FFTextArea
      :model-value="modelValue"
      :placeholder="placeholderText"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<style lang="postcss" scoped>
:deep(.v-field__input) {
  @apply w-full resize-none rounded-md border border-tint-80 px-4 py-4;
  @apply font-sans text-sm text-shade-900 transition-colors;
  @apply placeholder:text-shade-800 focus:border-highlight-500;
  @apply focus:outline-none;
  mask-image: none;
}
</style>
