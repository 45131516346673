<script setup lang="ts">
import { computed } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import { V1SnapshotScoreThreshold } from '@factoryfixinc/ats-interfaces';

import ExpandableSection from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/ExpandableSection/ExpandableSection.vue';
import MultipleSelectionItems from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/MultipleSelectionItems/MultipleSelectionItems.vue';
import SuggestionTextArea from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/SuggestionTextArea/SuggestionTextArea.vue';

const copilotActivationService = new CopilotActivationService();

const selectedScoreThreshold = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1ScoreThreshold;
});

const scoreThresholdItems = computed(() => {
  return [
    {
      title: 'Maximize your talent pipeline',
      subtitle: 'Copilot will contact promising candidates, even when their fit is less certain',
      icon: 'three-star' as const,
      isSelected: selectedScoreThreshold.value === V1SnapshotScoreThreshold.THREE_PLUS,
      tooltipText: '',
      value: `${V1SnapshotScoreThreshold.THREE_PLUS}`,
    },
    {
      title: 'Focus on top-tier matches only',
      subtitle: 'Copilot will only contact candidates with high likelihood of fit',
      icon: 'four-star' as const,
      isSelected: selectedScoreThreshold.value === V1SnapshotScoreThreshold.FOUR_PLUS,
      tooltipText: '',
      value: `${V1SnapshotScoreThreshold.FOUR_PLUS}`,
    },
  ];
});

const handleScoreThresholdSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1ScoreThreshold = value;
};
</script>

<template>
  <ExpandableSection title="Fine-tune Copilot's sourcing strategy (Optional)">
    <MultipleSelectionItems
      title="How selective should Copilot be when reaching out to passive candidates?"
      :items="scoreThresholdItems"
      class="mb-8"
      @click:select="handleScoreThresholdSelection"
    />
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1TargetCompanies"
      title="Which companies would you love to poach candidates from for this role?"
      :placeholder="`e.g. competitors or known companies with strong talent...`"
    />
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1AvoidCompanies"
      title="Which companies would you prefer not to poach candidates from for this role?"
      :placeholder="`e.g. affiliated/sister companies or places you prefer to avoid recruiting from...`"
    />
  </ExpandableSection>
</template>

<style lang="scss" scoped></style>
