<script setup lang="ts">
import { computed } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import { V1SnapshotStrictness } from '@factoryfixinc/ats-interfaces';

import ExpandableSection from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/ExpandableSection/ExpandableSection.vue';
import MultipleSelectionItems from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/MultipleSelectionItems/MultipleSelectionItems.vue';
import SuggestionTextArea from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/SuggestionTextArea/SuggestionTextArea.vue';

const copilotActivationService = new CopilotActivationService();

const selectedStrictness = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1Strictness;
});

const selectedStrictnessItems = computed(() => {
  return [
    {
      title: 'Lenient',
      subtitle: 'Consider candidates with transferable skills and potential to learn',
      icon: 'expand' as const,
      isSelected: selectedStrictness.value === V1SnapshotStrictness.LENIENT,
      tooltipText: '',
      value: `${V1SnapshotStrictness.LENIENT}`,
    },
    {
      title: 'Balanced',
      subtitle: 'Balance technical requirements with demonstrated mechanical aptitude',
      icon: 'scale' as const,
      isSelected: selectedStrictness.value === V1SnapshotStrictness.BALANCED,
      tooltipText: '',
      value: `${V1SnapshotStrictness.BALANCED}`,
    },
    {
      title: 'Strict',
      subtitle: 'Focus on candidates with exact experience and certifications needed',
      icon: 'target' as const,
      isSelected: selectedStrictness.value === V1SnapshotStrictness.STRICT,
      tooltipText: '',
      value: `${V1SnapshotStrictness.STRICT}`,
    },
  ];
});

const handleStrictnessSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1Strictness = value;
};
</script>

<template>
  <ExpandableSection title="Help Copilot understand your ideal candidate (Optional)">
    <MultipleSelectionItems
      title="How strictly should Copilot evaluate candidates?"
      :items="selectedStrictnessItems"
      class="mb-8"
      @click:select="handleStrictnessSelection"
    />
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1RelevantSkills"
      title="What experience level or skills separate an okay candidate from a great one for this position?"
      :placeholder="`e.g. experience optimizing production processes, ability to troubleshoot complex mechanical systems, cross-trained in multiple areas of manufacturing...`"
    />
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1RelevantBackground"
      title="What aspects of someone's background make you willing to overlook other shortcomings?"
      :placeholder="`e.g. extensive experience with similar manufacturing equipment, demonstrated ability to learn new systems quickly, strong mechanical aptitude with transferable skills...`"
    />
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1ImmediateRejectionReason"
      title="What would make you immediately reject a candidate?"
      :placeholder="`e.g. no experience with required machinery, lack of safety certifications, gaps in critical technical skills...`"
    />
  </ExpandableSection>
</template>

<style lang="scss" scoped></style>
