<script lang="ts" setup>
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import TextCheckOptions from '@/components/Jobs/CopilotActivation/TextCheckOptions.vue';
import { computed, ref, watch } from 'vue';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';
import ScheduledUpdatesService from '@/core/jobs/scheduled-updates/scheduled-updates.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import ScheduledUpdatesModal from '@/components/Jobs/CopilotActivation/ScheduledUpdatesModal.vue';
import ExclamationIcon from '@/assets/svg/exclamation-circle-16.svg?component';
import LightningCircleIcon from '@/assets/svg/lightning-circle-24.svg?component';
import { ProjectDistributionStatusType } from '@factoryfixinc/ats-interfaces';

const copilotActivationService = new CopilotActivationService();
const employerFeatureService = new EmployerFeatureService();
const scheduledUpdatesService = new ScheduledUpdatesService();

defineProps<{
  isDisabled: boolean;
  projectId?: number;
  copilotStatus: string;
}>();

const isScheduledUpdatesModalOpen = ref(false);
const showTooltip = ref(false);

const isScheduledUpdatesActive = computed<boolean>(
  () => copilotActivationService.scheduledUpdates === true,
);
const toggleScheduledUpdates = (selected: boolean) => {
  copilotActivationService.scheduledUpdates = selected;
};

const hasScheduledUpdatesFeature = computed<boolean>(
  () => employerFeatureService.hasScheduledUpdatesEnabled,
);

const hasScheduledUpdates = computed<boolean>(
  () => scheduledUpdatesService.currentScheduledUpdate !== null,
);

const isGeneratingScheduledUpdates = computed<boolean>(
  () =>
    hasScheduledUpdates.value &&
    scheduledUpdatesService.currentScheduledUpdate?.status ===
      ProjectDistributionStatusType.GENERATING,
);

const currentJobId = computed<number>(() => copilotActivationService.selectedJobId ?? 0);

watch(currentJobId, async (newJobId: number, previousJobId: number) => {
  if (newJobId !== 0 && newJobId !== previousJobId) {
    try {
      await scheduledUpdatesService.loadScheduledUpdateByJobId(newJobId);
    } catch (error) {
      scheduledUpdatesService.currentScheduledUpdate = null;
      ErrorService.captureException(error);
    }
  }
});
</script>

<template>
  <div
    v-if="hasScheduledUpdatesFeature"
    id="scheduled-updates"
    class="mt-6"
    :class="{ 'pointer-events-none opacity-75': isDisabled }"
  >
    <div class="scheduled-updates-title">
      How would you like Copilot to manage this job's visibility on job boards?
    </div>
    <div class="flex flex-row gap-3">
      <TextCheckOptions
        :is-selected="isScheduledUpdatesActive"
        badge-content="RECOMMENDED"
        @click="toggleScheduledUpdates(true)"
        ><template v-slot:title>Optimize visibility</template>
        Copilot will adapt the title and description periodically to maintain high rankings and
        steady applicant flow
        <template v-slot:action>
          <template v-if="hasScheduledUpdates">
            <div
              v-if="isGeneratingScheduledUpdates"
              class="mt-0.5 flex items-center text-sm text-shade-800"
            >
              <LightningCircleIcon class="mr-0.5 scale-75" />
              <span class="breath-effect">Generating updates...</span>
            </div>
            <div
              v-else
              @click.prevent.stop="isScheduledUpdatesModalOpen = true"
              class="mt-0.5 cursor-pointer text-sm font-bold text-highlight-700 hover:opacity-70"
            >
              See scheduled changes
            </div>
          </template>
          <div v-else class="mt-0.5 flex items-center text-sm text-shade-800">
            <v-tooltip
              v-model="showTooltip"
              location="bottom"
              content-class="project-card-tooltip tooltip-top-arrow"
            >
              <template #activator="{ props }">
                <div v-bind="props">
                  <!-- Figma design shows an "i" for Information thats why I am rotating the
                   Exclamation icon we already have -->
                  <ExclamationIcon class="mr-0.5 rotate-180" />
                </div>
              </template>
              <span class="text-xs">
                Scheduled updates will be generated<br />
                once Copilot is activated
              </span>
            </v-tooltip>
            <!-- Tooltip design in Figma show it centered over the icon: this will allow
             to show when hovering on the text too -->
            <span @mouseover="showTooltip = true" @mouseleave="showTooltip = false"
              >See scheduled changes</span
            >
          </div>
        </template>
      </TextCheckOptions>
      <TextCheckOptions
        :is-selected="!isScheduledUpdatesActive"
        @click="toggleScheduledUpdates(false)"
        ><template v-slot:title>Keep original</template>
        Always use your exact job details; rankings will decline over time, reducing inbound
        applicant traffic
      </TextCheckOptions>
    </div>
  </div>
  <ScheduledUpdatesModal
    :model-value="isScheduledUpdatesModalOpen"
    :project-id="projectId"
    :copilot-status="copilotStatus"
    @exit="isScheduledUpdatesModalOpen = false"
  ></ScheduledUpdatesModal>
</template>
<style lang="postcss" scoped>
.scheduled-updates-title {
  @apply mb-4 text-lg font-extrabold;
}
@keyframes breathe {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.breath-effect {
  animation: breathe 1.5s ease-in-out infinite;
}
</style>
