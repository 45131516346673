import {
  type ProjectCopilotConfiguration,
  type ProjectCopilotConfigurationV1Snapshot,
  V1SnapshotCommunicationTone,
  V1SnapshotMessagingWindow,
  V1SnapshotPushiness,
  V1SnapshotScoreThreshold,
  V1SnapshotStrictness,
  V1SnapshotThoroughness,
} from '@factoryfixinc/ats-interfaces';

export default class ProjectCopilotConfigurationUtils {
  public static parseProjectCopilotConfiguration(
    projectCopilotConfiguration?: ProjectCopilotConfiguration | null,
  ): ProjectCopilotConfigurationV1Snapshot {
    if (!projectCopilotConfiguration) {
      return this.fillV1SnapshotWithDefaultValues({});
    }

    switch (projectCopilotConfiguration.version) {
      case 'v1':
        const snapshot = this.parseV1Snapshot(projectCopilotConfiguration.snapshot);
        return this.fillV1SnapshotWithDefaultValues(snapshot);
      default:
        throw new Error(
          `Unsupported project copilot configuration version: ${projectCopilotConfiguration.version}`,
        );
    }
  }

  private static fillV1SnapshotWithDefaultValues(
    snapshot: ProjectCopilotConfigurationV1Snapshot,
  ): ProjectCopilotConfigurationV1Snapshot {
    if (!snapshot.strictness) {
      snapshot.strictness = V1SnapshotStrictness.BALANCED;
    }

    if (!snapshot.scoreThreshold) {
      snapshot.scoreThreshold = V1SnapshotScoreThreshold.FOUR_PLUS;
    }

    if (!snapshot.communicationTone) {
      snapshot.communicationTone = V1SnapshotCommunicationTone.PROFESSIONAL_FORMAL;
    }

    if (!snapshot.pushiness) {
      snapshot.pushiness = V1SnapshotPushiness.COMMON_SENSE;
    }

    if (!snapshot.thoroughness) {
      snapshot.thoroughness = V1SnapshotThoroughness.STANDARD;
    }

    if (!snapshot.messagingWindow) {
      snapshot.messagingWindow = V1SnapshotMessagingWindow.WHENEVER_ENGAGED;
    }

    return snapshot;
  }

  private static parseV1Snapshot(snapshot: string): ProjectCopilotConfigurationV1Snapshot {
    return JSON.parse(snapshot) as ProjectCopilotConfigurationV1Snapshot;
  }
}
