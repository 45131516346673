<script setup lang="ts">
/* eslint-disable max-lines */
import { computed, onBeforeMount, onMounted, reactive, ref, watch } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import { sleep } from 'radash';
import ProjectService from '@/core/shared/project/project.service';
import { EmployerFeatureService } from '@/core/shared/employer-feature/employer-feature.service';
import { ErrorService } from '@/core/shared/errors/error.service';
import { SnackbarService } from '@/core/shared/snackbar/snackbar.service';
import SubscriptionService from '@/core/shared/subscription/subscription.service';
import JobHeaders from '@/components/Jobs/JobHeaders/JobHeaders.vue';
import JobDetailsForm from '@/components/Jobs/CopilotActivation/JobDetailsForm.vue';
import JobDetailsFormSkeleton from '@/components/Jobs/CopilotActivation/JobDetailsFormSkeleton.vue';
import JobSkillsForm from '@/components/Jobs/CopilotActivation/JobSkillsForm.vue';
import JobDescriptionForm from '@/components/Jobs/CopilotActivation/JobDescriptionForm.vue';
import CandidateScreeningForm from '@/components/Jobs/CopilotActivation/CandidateScreeningForm.vue';
import ScreenerQuestionsForm from '@/components/Jobs/CopilotActivation/ScreenerQuestionsForm.vue';
import ExitCopilotActivationModal from '@/components/Jobs/CopilotActivation/ExitCopilotActivationModal.vue';
import VersionHistoryList from '@/components/Jobs/CopilotActivation/VersionHistoryList.vue';
import CopilotActivationButtons from '@/components/Jobs/CopilotActivation/CopilotActivationButtons.vue';
import ScheduledUpdatesOptions from '@/components/Jobs/CopilotActivation/ScheduledUpdatesOptions.vue';
import ActiveOutreachOptions from '@/components/Jobs/CopilotActivation/ActiveOutreachOptions.vue';
import FormSection from '@/components/Jobs/CopilotActivation/FormSection.vue';
import SourcingPreferencesSection from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/SourcingPreferencesSection.vue';
import CommunicationPreferencesSection from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/CommunicationPreferencesSection.vue';

import {
  JobStatus,
  type Project,
  ScreeningAction,
  ScreeningType,
} from '@factoryfixinc/ats-interfaces';
import type { VForm } from 'vuetify/components';
import SpinnerLoader from '@/components/Shared/Loaders/SpinnerLoader.vue';
import TrackingService from '@/core/shared/tracking/tracking.service';
import { TrackingActionName, TrackingCopilotSource } from '@/core/shared/tracking/tracking-actions';
import VersionsService from '@/core/jobs/versions/versions.service';
import { JobVersionViewStatus } from '@/core/jobs/versions/types/job-version-history.type';
import type { JobStatusHistory } from '@factoryfixinc/ats-interfaces/dist/types/job-status-history.model';
import { SearchService } from '@/core/sourcing/search/search.service';
import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';
import {
  MAXIMUN_ALLOWED_SCREENER_QUESTIONS,
  MINIMUM_REQUIRED_SCREENER_QUESTIONS,
} from '@/core/jobs/copilot-activation/types/local-screener-question.type';
import { retry } from 'radash';

const copilotActivationService = new CopilotActivationService();
const projectService = new ProjectService();
const employerFeatureService = new EmployerFeatureService();
const subscriptionService = new SubscriptionService();
const jobVersionsService = new VersionsService();
const searchService = new SearchService();

const display = useDisplay();
const route = useRoute();
const router = useRouter();

const isHeadersDrawerOpen = ref(true);
const isValidForm = ref(false);
const copilotForm = ref<VForm | null>(null);
const isExitModalOpen = ref(false);
const isLoadingCopilot = ref(false);
const isLoading = ref(false);
const project = ref<Project>();
const canEditForm = ref(false);
const originalJobTitle = ref('');
const screeningType = ref<ScreeningType>();
const jobStatusHistory = ref<JobStatusHistory[]>([]);
const clonedLocalJobDataInformation = ref('');
const shouldShowModal = ref<boolean>(false);
const showSkeletonLoader = ref(false);

const loadedRequirements = reactive({
  jobInformation: false,
  jobDetailsForm: true,
  jobSkillsForm: true,
  jobDescriptionForm: false,
});

// Computed properties
const isDesktop = computed(() => display.mdAndUp.value);
const isFormReady = computed(() => Object.values(loadedRequirements).every(Boolean));
const isDoingCandidateScreening = computed(() => employerFeatureService.hasUrlScreeningEnabled);
const isDoingZeroScreening = computed(() => employerFeatureService.hasZeroScreeningEnabled);
const selectedAction = computed<ScreeningAction | null>(
  () => copilotActivationService.screeningAction || ScreeningAction.SCREEN,
);

const screeningIsStatic = computed(
  () =>
    selectedAction.value === ScreeningAction.SCREEN &&
    copilotActivationService.screeningType === ScreeningType.STATIC,
);

const isDoingScreenerQuestions = computed(
  () =>
    !isDoingZeroScreening.value &&
    (!isDoingCandidateScreening.value ||
      (isDoingCandidateScreening.value && selectedAction.value === ScreeningAction.SCREEN)),
);

const projectId = computed(() => {
  const id = Number(route.params.id);
  return Number.isNaN(id) ? undefined : id;
});

const shouldValidate = computed(() => !!route.query.validate);

const hasAlreadyCopilotActivated = computed(() => {
  return copilotActivationService.jobStatus === JobStatus.LIVE;
});

const hasUsedAllJobSlots = computed(() => {
  return subscriptionService.usedJobSlots >= subscriptionService.jobSlots;
});

const isShowingAdditionalChargeNotice = computed(() => {
  return hasUsedAllJobSlots.value && !hasAlreadyCopilotActivated.value;
});

const isDisabled = computed(() => {
  return isLoading.value || jobVersionsService.isLoadingJobVersion || !canEditForm.value;
});

const isLoadingOneJobVersion = computed(() => jobVersionsService.isLoadingJobVersion);

const selectedVersionId = computed(() => jobVersionsService.currentJobVersionId);

const selectedJobVersionDate = computed(() => {
  if (jobVersionsService.currentJobVersion) {
    return VersionsService.formatJobVersionDate(
      new Date(jobVersionsService.currentJobVersion.createTs),
    );
  }
  return '';
});

const isCopilotActivated = computed(() => {
  return projectService.currentProject?.copilot;
});

const calculateLocation = computed(() => {
  const { city, state } = copilotActivationService;
  return `${city ? `${city}, ` : ''}${state ? `${state}, ` : ''}USA`;
});

const shouldUpdateSavedSearch = computed(() => {
  const displayTitle = (copilotActivationService.displayTitle ?? '').trim();
  return originalJobTitle.value === '' && displayTitle !== '';
});

const copilotTrackingStatus = computed(() => {
  return hasAlreadyCopilotActivated.value ? 'activated' : 'deactivated';
});

const isAtsSynced = computed(() => (!project.value ? false : project.value.remoteJobId !== null));

// Check if this is a new project by verifying if all conditions are true:
// 1. There are no job versions (new job)
// 2. The project's update timestamp is very close to its creation timestamp (indicating no actual updates)
// 3. The project has no title or has the default "No Title"
const isNewProject = computed(() => {
  if (!project.value) return false;

  const updateTime = new Date(project.value.updateTs || 0).getTime();
  const createTime = new Date(project.value.createTs || 0).getTime();
  const timeDifferenceInSeconds = Math.abs(updateTime - createTime) / 1000;
  const isRecentlyCreated = timeDifferenceInSeconds <= 5;

  const noJobVersions = jobVersionsService.jobVersions.length === 0;

  // Edge case: some times the side effect creates the first version when the user lands on the form
  const isOneJobVersion = jobVersionsService.jobVersions.length === 1;
  const isRecentlyVersionCreated =
    isOneJobVersion &&
    Math.abs(new Date(jobVersionsService.jobVersions[0].createTs).getTime() - createTime) / 1000 <=
      5;

  const projectTitle = (project.value.title || '').trim().toLowerCase();
  const hasDefaultOrNoTitle = !projectTitle || projectTitle === 'no title';

  return (noJobVersions || isRecentlyVersionCreated) && isRecentlyCreated && hasDefaultOrNoTitle;
});

const wasCopilotActivated = computed(() => {
  return jobStatusHistory.value.some((history) => history.status === JobStatus.LIVE);
});

const isJobVersionEditMode = computed(() => {
  return jobVersionsService.currentJobVersionViewStatus === JobVersionViewStatus.EDIT_MODE;
});

const isJobVersionViewMode = computed(() => {
  return jobVersionsService.currentJobVersionViewStatus === JobVersionViewStatus.VIEW_MODE;
});

async function loadProjectInformation() {
  try {
    if (!projectId.value) {
      await redirectToConversations();
      return;
    }
    project.value = await projectService.getProjectById(projectId.value);
    await loadJobInformation(project.value);
    loadedRequirements.jobInformation = true;
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load job. Please try again later.');
    redirectToConversations();
  }
}

async function loadJobInformation(project: Project) {
  try {
    const job = await copilotActivationService.setupStoreByProject(project);

    if (job) {
      jobStatusHistory.value = job.jobStatusHistories ?? [];
      originalJobTitle.value = copilotActivationService.displayTitle ?? '';
      screeningType.value = job.screeningType;
    }
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to load job. Please try again later.');
  }
}

async function redirectToConversations() {
  return router.push('/conversations');
}

function exitCopilotFlow() {
  if (window.history.length > 1) {
    router.back();
    return;
  }
  router.push(`/sourcing/job/${projectId.value}`);
}

async function updateJob(payload: { activate: boolean; byPassModal?: boolean }) {
  payload.byPassModal = payload.byPassModal ?? false;
  const { activate, byPassModal } = payload;
  try {
    const shouldValidateForm = activate || !!isCopilotActivated.value;
    shouldShowModal.value = byPassModal;
    isLoadingCopilot.value = activate; // To show the loading spinner for the Copilot button
    isLoading.value = !activate; //  or the Save & Exit button individually
    copilotActivationService.hasTriedToUpdateJob = shouldValidateForm;

    // Validate the form if we are activating Copilot or its already activated
    if (copilotForm.value && shouldValidateForm) {
      await copilotForm.value.validate();
    }

    // Double check screener questions as those handle validation independently.
    const isCheckingScreenerQuestions = isDoingScreenerQuestions.value;
    let areScreenerQuestionsValid = true;
    if (isCheckingScreenerQuestions && shouldValidateForm) {
      if (copilotActivationService.screeningType == ScreeningType.STATIC) {
        const validQuestions = copilotActivationService.screenerQuestions.filter(
          (question) => question.text.trim().length > 0,
        );

        areScreenerQuestionsValid =
          validQuestions.length >= MINIMUM_REQUIRED_SCREENER_QUESTIONS &&
          validQuestions.length <= MAXIMUN_ALLOWED_SCREENER_QUESTIONS;
      }
    }

    // Also double check the job description validity.
    const validJobDescription = `${copilotActivationService.rawDescription}`.trim().length > 0;

    // Only validate the form if we are activating the job or if the Job is already Copilot activated
    if (
      shouldValidateForm &&
      (!isValidForm.value || !areScreenerQuestionsValid || !validJobDescription || !projectId.value)
    ) {
      // If the form is invalid, wait for a brief moment, find the first invalid input field, and scroll to it smoothly.
      await sleep(300);
      const firstInvalidInput = document.querySelector('.v-field--error');
      if (firstInvalidInput) {
        const rect = firstInvalidInput.getBoundingClientRect();
        const offset = window.scrollY + rect.top - 32;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
      return;
    }

    await copilotActivationService.updateJob(copilotActivationService.scheduledUpdates);

    if (projectId.value) {
      // Update the project title and location since we eliminated the Modal
      await projectService.updateProject(projectId.value, {
        title: copilotActivationService.displayTitle ?? '',
        location: calculateLocation.value,
        generateQueryForSavedSearch: shouldUpdateSavedSearch.value,
        scheduledUpdates: copilotActivationService.scheduledUpdates,
        projectCopilotConfiguration: {
          version: 'v1',
          snapshot: JSON.stringify(copilotActivationService.projectCopilotConfiguration),
        },
      });

      if (activate) {
        await projectService.updateProjectCopilotStatus(projectId.value, true);

        // Only track the abandoned event if copilot was not active
        if (!hasAlreadyCopilotActivated.value) {
          TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_COMPLETED, {
            source: TrackingCopilotSource.COPILOT_ACTIVATION_PAGE,
            project_id: projectId.value,
            job_id: copilotActivationService.selectedJobId,
          });
          // Update the local value for the UI since the DB change is done as a side effect
          copilotActivationService.jobStatus = JobStatus.LIVE;
        }
      } else {
        TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_CHANGES_SAVED, {
          source: TrackingCopilotSource.COPILOT_ACTIVATION_PAGE,
          copilot_active: copilotTrackingStatus.value,
          project_id: projectId.value,
          job_id: copilotActivationService.selectedJobId,
        });
      }
      // We need to reload the project to get the updated values
      // or else if we go back, the Saved Search will not be updated
      await projectService.getProjectById(projectId.value);
      searchService.setSelectedFiltersFromProject(projectService.currentProject);
    }

    SnackbarService.success('Job updated successfully');
    exitCopilotFlow();
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to update job. Please try again later.');
  } finally {
    isLoading.value = false;
    isLoadingCopilot.value = false;
  }
}

onMounted(() => {
  loadProjectInformation();
  canEditForm.value = true;
  window.scrollTo({ top: 0, behavior: 'auto' }); // Scroll top
});

onBeforeMount(() => {
  jobVersionsService.resetJobVersions();
});

watch(projectId, (newValue) => {
  if (!newValue) return redirectToConversations();
});

watch(isFormReady, (newValue) => {
  if (newValue) {
    window.scrollTo({ top: 0, behavior: 'auto' }); // Scroll top

    // We need to clone the localJobData to compare it later to the Form data changes made by the user
    clonedLocalJobDataInformation.value = JSON.stringify(
      copilotActivationService.localJobDataInformation,
    );

    // If we come from the Copilot Activation button from ProjectCard,
    // we need to validate the form to show the user the errors
    if (shouldValidate.value) {
      setTimeout(() => updateJob({ activate: true, byPassModal: false }), 750);
    }
  }
});

/**
 * On an attempt to leave the page, show the exit modal, else allow leaving the page
 * if the form is valid or if the exit modal is already visible. When leaving the page,
 * refresh the projects list.
 */
onBeforeRouteLeave(async (to, from, next) => {
  const page = document.getElementsByTagName('body')[0];
  page.classList.remove('noscroll');

  if (shouldShowModal.value || isValidForm.value || isExitModalOpen.value) {
    // Only delete the project if it's new AND the user is abandoning it
    if (isNewProject.value && projectId.value !== undefined && isExitModalOpen.value) {
      try {
        // First archive the project, we cannot delete it directly
        await projectService.archiveProject(projectId.value);

        // Then delete the archived project, retry just in case the project is not ready to be deleted
        await retry({ times: 4, delay: 2000 }, async () => {
          if (projectId.value !== undefined) {
            await projectService.deleteProject(projectId.value);
          }
        });
      } catch (error) {
        ErrorService.captureException(error);
      }
    }

    await projectService.searchProjects({ skipTracking: true });
    //If we exit the FORM, we need to reset the Job Versions for the project
    jobVersionsService.currentJobVersion = null;
    jobVersionsService.currentJobVersionId = null;
    copilotActivationService.scheduledUpdates = false;
    if (isExitModalOpen.value) {
      TrackingService.trackAction(TrackingActionName.COPILOT_ENABLE_ABANDONED, {
        project_id: projectId.value,
        job_id: copilotActivationService.selectedJobId,
      });
    }
    next();
    return;
  }

  isExitModalOpen.value = true;

  next(false);
});

watch(selectedVersionId, async (newValue, oldValue) => {
  if (newValue === oldValue) {
    return Promise.resolve();
  }

  showSkeletonLoader.value = true;

  if (jobVersionsService.jobVersionWithRelations) {
    // Project is empty, redirect to conversations.
    // The project should be already set by the time we get here.
    if (!project.value) {
      await redirectToConversations();
      showSkeletonLoader.value = false;
      return;
    }

    await copilotActivationService.setupStoreByProject(project.value, true);

    // If the selected ID is the same as the first version in the list then we are in normal mode.
    if (jobVersionsService.jobVersions[0].id === newValue) {
      jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.NORMAL_MODE;
      canEditForm.value = true;
    } else {
      jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.VIEW_MODE;
      canEditForm.value = false;
    }
  }
  showSkeletonLoader.value = false;
});

// To disable scrolling when loading a job version
watch(isLoadingOneJobVersion, (newValue) => {
  const page = document.getElementsByTagName('body')[0];
  if (newValue) {
    page.classList.add('noscroll');
  } else {
    page.classList.remove('noscroll');
  }
  showSkeletonLoader.value = newValue;
});

const enableJobVersionEditing = () => {
  jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.EDIT_MODE;
  canEditForm.value = true;
};

const disableJobVersionEditing = () => {
  jobVersionsService.currentJobVersionViewStatus = JobVersionViewStatus.VIEW_MODE;
  canEditForm.value = false;
};

async function disableCopilot() {
  // Since we are disabling Copilot, will navigate to the previous page
  // so we dont need to show the modal
  shouldShowModal.value = false;

  if (!projectId.value) return;
  try {
    isLoadingCopilot.value = true;
    await projectService.updateProjectCopilotStatus(projectId.value, false);
    copilotActivationService.jobStatus = JobStatus.DRAFT;

    TrackingService.trackAction(TrackingActionName.COPILOT_DISABLED, {
      source: TrackingCopilotSource.COPILOT_ACTIVATION_PAGE,
      project_id: projectId.value,
      job_id: copilotActivationService.selectedJobId,
    });

    SnackbarService.success('Copilot disabled successfully');
    exitCopilotFlow();
  } catch (error) {
    ErrorService.captureException(error);
    SnackbarService.critical('Failed to disable Copilot. Please try again later.');
  } finally {
    isLoadingCopilot.value = false;
  }
}
</script>

<template>
  <div class="h-full w-full pl-8">
    <v-navigation-drawer
      width="336"
      v-model:model-value="isHeadersDrawerOpen"
      color="shade-860"
      class="px-4 pt-8"
      :permanent="isDesktop"
    >
      <JobHeaders v-if="isFormReady" @click:back="isExitModalOpen = true" />
    </v-navigation-drawer>
    <div class="form-container">
      <!-- Main Content -->
      <div class="flex">
        <v-form
          v-model="isValidForm"
          v-show="isFormReady"
          class="copilot-activation-form mx-auto max-w-[696px] py-7"
          ref="copilotForm"
          @submit.prevent
        >
          <template v-if="showSkeletonLoader">
            <JobDetailsFormSkeleton />
          </template>
          <template v-else>
            <div v-if="isJobVersionEditMode" class="banner restored">
              <strong>Restored version ({{ selectedJobVersionDate }})</strong>. Save this version to
              overwrite the active job posting.
            </div>
            <div v-if="isJobVersionViewMode" class="banner view-only">
              You are viewing an older version.
              <a
                href="#"
                class="font-sans text-sm font-semibold text-highlight-600 transition-colors hover:!text-highlight-800"
                @click.prevent="enableJobVersionEditing"
                >Restore</a
              >
              this version to edit it.
            </div>
            <span v-if="isJobVersionViewMode" class="float-right inline-block">
              <v-btn
                :ripple="false"
                class="modal-button-secondary"
                variant="flat"
                @click.prevent="enableJobVersionEditing"
                >Restore to edit</v-btn
              >
            </span>
            <JobDetailsForm :is-disabled="isDisabled" />
            <JobDescriptionForm
              @init:editor="loadedRequirements.jobDescriptionForm = true"
              :disabled="isDisabled"
            />
            <JobSkillsForm :is-disabled="isDisabled" />

            <FormSection
              id="sourcing-strategy"
              title="Align on Copilot's sourcing strategy"
              subtitle="Choose where and how Copilot finds the best candidates for this role"
              add-divider
            >
              <ActiveOutreachOptions :is-disabled="isDisabled" />
              <ScheduledUpdatesOptions
                :is-disabled="isDisabled"
                :project-id="projectId"
                :copilot-status="copilotTrackingStatus"
              />
              <SourcingPreferencesSection :is-disabled="isDisabled" class="mt-6" />
            </FormSection>

            <template v-if="!isDoingZeroScreening">
              <CandidateScreeningForm :is-disabled="isDisabled" />
              <CommunicationPreferencesSection
                :is-disabled="isDisabled"
                v-show="!screeningIsStatic"
              />
              <ScreenerQuestionsForm :is-disabled="isDisabled" v-show="screeningIsStatic" />
            </template>
          </template>
        </v-form>
        <div
          v-show="isFormReady"
          class="ml-8 w-[305px] min-w-[305px] border-l-[1px] border-tint-40 p-6"
        >
          <p class="mb-4 font-sans text-lg font-extrabold leading-7 text-black">Version History</p>
          <div v-if="isJobVersionEditMode" class="restored-list-item">
            Restored version ({{ selectedJobVersionDate }})
            <p>Unpublished</p>
          </div>
          <VersionHistoryList
            :key="projectId"
            :is-job-version-edit-mode="isJobVersionEditMode"
            :project-id="projectId"
            :is-copilot-activated="hasAlreadyCopilotActivated || wasCopilotActivated"
          />
        </div>
      </div>
      <template v-if="!isJobVersionViewMode">
        <CopilotActivationButtons
          v-show="isFormReady && !showSkeletonLoader"
          class="sticky-button-container"
          :isLoading="isLoading"
          :isLoadingCopilot="isLoadingCopilot"
          :isCopilotActivated="isCopilotActivated"
          :isJobVersionEditMode="isJobVersionEditMode"
          :isAtsSynced="isAtsSynced"
          :isShowingAdditionalChargeNotice="isShowingAdditionalChargeNotice"
          @update-job="updateJob"
          @disable-copilot="disableCopilot"
          @disable-job-version-editing="disableJobVersionEditing"
        />
      </template>
    </div>
    <div v-show="!isFormReady" class="flex h-full w-full items-center justify-center">
      <SpinnerLoader />
    </div>
    <!-- Exit modal LINT is forcing me to mutate isExitModalOpen -->
    <ExitCopilotActivationModal
      :model-value="isExitModalOpen"
      @cancel="isExitModalOpen = false"
      @exit="exitCopilotFlow"
    />
  </div>
</template>

<style lang="postcss" src="./copilot-activation.css" scoped></style>
