<script lang="ts" setup>
import { computed } from 'vue';
import TargetComponent from '@/assets/svg/target.svg?component';
import ExpandIconComponent from '@/assets/svg/expand-icon.svg?component';
import ScalesComponent from '@/assets/svg/scales.svg?component';
import InfoCircleComponent from '@/assets/svg/info-circle.svg?component';
import ThreeStarIconComponent from '@/assets/svg/3-star-icon.svg?component';
import FourStarIconComponent from '@/assets/svg/4-star-icon.svg?component';
import HeartIconComponent from '@/assets/svg/heart.svg?component';
import BriefcaseIconComponent from '@/assets/svg/briefcase.svg?component';
import BoltFastIconComponent from '@/assets/svg/bolt-fast.svg?component';
import CheckmarkIconComponent from '@/assets/svg/checkmark.svg?component';
import ClockIconComponent from '@/assets/svg/clock.svg?component';
import SunIconComponent from '@/assets/svg/sun.svg?component';
import ListIconComponent from '@/assets/svg/list.svg?component';
import MagnifyingGlassIconComponent from '@/assets/svg/magnifying-glass.svg?component';
import TaskCheckIconComponent from '@/assets/svg/task-check.svg?component';
import MegaphoneIconComponent from '@/assets/svg/megaphone.svg?component';

const props = defineProps<{
  title: string;
  items: {
    title: string;
    subtitle: string;
    icon:
      | 'target'
      | 'expand'
      | 'scale'
      | 'three-star'
      | 'four-star'
      | 'heart'
      | 'briefcase'
      | 'bolt-fast'
      | 'checkmark'
      | 'clock'
      | 'sun'
      | 'list'
      | 'magnifying-glass'
      | 'task-check'
      | 'megaphone';
    isSelected: boolean;
    tooltipText?: string;
    value: string;
  }[];
}>();

const emit = defineEmits<{
  (e: 'click:select', item: string): void;
}>();

const titleText = computed(() => props.title);
const items = computed(() => props.items);

const handleClick = (item: { value: string }) => {
  emit('click:select', item.value);
};

const getIcon = (
  icon:
    | 'target'
    | 'expand'
    | 'scale'
    | 'three-star'
    | 'four-star'
    | 'heart'
    | 'briefcase'
    | 'bolt-fast'
    | 'checkmark'
    | 'clock'
    | 'sun'
    | 'list'
    | 'magnifying-glass'
    | 'task-check'
    | 'megaphone',
) => {
  switch (icon) {
    case 'target':
      return TargetComponent;
    case 'expand':
      return ExpandIconComponent;
    case 'scale':
      return ScalesComponent;
    case 'three-star':
      return ThreeStarIconComponent;
    case 'four-star':
      return FourStarIconComponent;
    case 'heart':
      return HeartIconComponent;
    case 'briefcase':
      return BriefcaseIconComponent;
    case 'bolt-fast':
      return BoltFastIconComponent;
    case 'checkmark':
      return CheckmarkIconComponent;
    case 'clock':
      return ClockIconComponent;
    case 'sun':
      return SunIconComponent;
    case 'list':
      return ListIconComponent;
    case 'magnifying-glass':
      return MagnifyingGlassIconComponent;
    case 'task-check':
      return TaskCheckIconComponent;
    case 'megaphone':
      return MegaphoneIconComponent;
    default:
      return null;
  }
};

const hasLargeIcon = computed(() => {
  return items.value.some((item) => item.icon === 'three-star' || item.icon === 'four-star');
});
</script>

<template>
  <div class="cursor-pointer">
    <!-- Title -->
    <div class="text-shade-88 mb-3 font-sans text-lg font-extrabold leading-[27px]">
      {{ titleText }}
    </div>

    <!-- Items -->
    <div
      v-for="item in items"
      :key="item.value"
      class="mb-4 flex h-[72px] items-center rounded-md border px-4 py-[19px]"
      :class="{
        'border-2 border-highlight-500': item.isSelected,
        'border border-tint-80': !item.isSelected,
      }"
      @click="handleClick(item)"
    >
      <!-- Icon -->
      <div
        class="mr-3 flex h-10 items-center justify-center rounded-md"
        :class="{
          'bg-tint-20': !item.isSelected,
          'bg-[#F7EAFF]': item.isSelected,
          'w-10': !hasLargeIcon,
        }"
      >
        <component
          :is="getIcon(item.icon)"
          :class="{
            'text-tint-400': !item.isSelected,
            'text-highlight-500': item.isSelected,
          }"
        />
      </div>

      <!-- Title and Subtitle -->
      <div class="mx-4 flex-grow font-sans text-sm leading-[14px]">
        <span class="mb-1.5 block font-extrabold text-shade-900">
          {{ item.title }}
        </span>
        <span class="block font-normal text-shade-820">
          {{ item.subtitle }}
        </span>
      </div>

      <!-- Tooltip -->
      <div v-show="item.tooltipText">
        <InfoCircleComponent class="h-4 w-4 text-shade-800" />
        <v-tooltip
          activator="parent"
          location="right"
          max-width="300"
          content-class="bg-white shadow-md text-shade-880 !text-sm !py-2 !px-3 !rounded-md"
        >
          <strong>Example: </strong>{{ item.tooltipText }}
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
