<script setup lang="ts">
import { computed } from 'vue';

import CopilotActivationService from '@/core/jobs/copilot-activation/copilot-activation.service';

import {
  V1SnapshotCommunicationTone,
  V1SnapshotMessagingWindow,
  V1SnapshotPushiness,
  V1SnapshotThoroughness,
} from '@factoryfixinc/ats-interfaces';

import ExpandableSection from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/ExpandableSection/ExpandableSection.vue';
import MultipleSelectionItems from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/MultipleSelectionItems/MultipleSelectionItems.vue';
import SuggestionTextArea from '@/components/Jobs/CopilotActivation/ProjectCopilotConfiguration/SuggestionTextArea/SuggestionTextArea.vue';

const copilotActivationService = new CopilotActivationService();

// SELECTED VALUES
const selectedCommunicationTone = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1CommunicationTone;
});

const selectedPushiness = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1Pushiness;
});

const selectedThoroughness = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1Thoroughness;
});

const selectedMessagingWindow = computed(() => {
  return copilotActivationService.projectCopilotConfigurationV1MessagingWindow;
});

// ITEMS
const communicationToneItems = computed(() => {
  return [
    {
      title: 'Friendly & personal',
      subtitle: 'Warm and conversational tone',
      icon: 'heart' as const,
      isSelected: selectedCommunicationTone.value === V1SnapshotCommunicationTone.FRIENDLY_PERSONAL,
      tooltipText: '',
      value: `${V1SnapshotCommunicationTone.FRIENDLY_PERSONAL}`,
    },
    {
      title: 'Professional & formal',
      subtitle: 'Business-like and respectful',
      icon: 'briefcase' as const,
      isSelected:
        selectedCommunicationTone.value === V1SnapshotCommunicationTone.PROFESSIONAL_FORMAL,
      tooltipText: '',
      value: `${V1SnapshotCommunicationTone.PROFESSIONAL_FORMAL}`,
    },
    {
      title: 'Concise & efficient',
      subtitle: 'Clear and to-the-point communication',
      icon: 'target' as const,
      isSelected: selectedCommunicationTone.value === V1SnapshotCommunicationTone.CONCISE_EFFICIENT,
      tooltipText: '',
      value: `${V1SnapshotCommunicationTone.CONCISE_EFFICIENT}`,
    },
  ];
});

const pushinessItems = computed(() => {
  return [
    {
      title: 'Move on quickly',
      subtitle: 'Takes the first "no" at face value and doesn’t follow up after no response',
      icon: 'bolt-fast' as const,
      isSelected: selectedPushiness.value === V1SnapshotPushiness.MOVE_ON_QUICKY,
      tooltipText: '',
      value: `${V1SnapshotPushiness.MOVE_ON_QUICKY}`,
    },
    {
      title: 'Pursue with common sense',
      subtitle: 'Makes reasonable attempts to re-engage and explores initial objections',
      icon: 'scale' as const,
      isSelected: selectedPushiness.value === V1SnapshotPushiness.COMMON_SENSE,
      tooltipText: '',
      value: `${V1SnapshotPushiness.COMMON_SENSE}`,
    },
    {
      title: 'Sell the opportunity hard',
      subtitle: 'Works actively to overcome objections and makes multiple follow-up attempts',
      icon: 'megaphone' as const,
      isSelected: selectedPushiness.value === V1SnapshotPushiness.SELL_HARD,
      tooltipText: '',
      value: `${V1SnapshotPushiness.SELL_HARD}`,
    },
  ];
});

const thoroughnessItems = computed(() => {
  return [
    {
      title: 'Just check the basics',
      subtitle:
        'Verify alignment on shift, pay, and location without digging into their experience',
      icon: 'task-check' as const,
      isSelected: selectedThoroughness.value === V1SnapshotThoroughness.QUICK_SCREEN,
      tooltipText: '',
      value: `${V1SnapshotThoroughness.QUICK_SCREEN}`,
    },
    {
      title: 'Screen for core qualifications',
      subtitle: 'Check the basics and dig into their experience through targeted questions',
      icon: 'list' as const,
      isSelected: selectedThoroughness.value === V1SnapshotThoroughness.STANDARD,
      tooltipText: '',
      value: `${V1SnapshotThoroughness.STANDARD}`,
    },
    {
      title: 'Dive deep into their experience',
      subtitle: 'Thoroughly explore work history and skills through detailed follow-up questions',
      icon: 'magnifying-glass' as const,
      isSelected: selectedThoroughness.value === V1SnapshotThoroughness.COMPREHENSIVE,
      tooltipText: '',
      value: `${V1SnapshotThoroughness.COMPREHENSIVE}`,
    },
  ];
});

const messagingWindowItems = computed(() => {
  return [
    {
      title: 'Whenever candidate is engaged',
      subtitle: 'Respond as soon as the candidate is active',
      icon: 'checkmark' as const,
      isSelected: selectedMessagingWindow.value === V1SnapshotMessagingWindow.WHENEVER_ENGAGED,
      tooltipText: '',
      value: `${V1SnapshotMessagingWindow.WHENEVER_ENGAGED}`,
    },
    {
      title: 'Daytime hours',
      subtitle: 'Message during typical waking hours',
      icon: 'sun' as const,
      isSelected: selectedMessagingWindow.value === V1SnapshotMessagingWindow.DAYTIME_HOURS,
      tooltipText: '',
      value: `${V1SnapshotMessagingWindow.DAYTIME_HOURS}`,
    },
    {
      title: 'Business hours',
      subtitle: 'Stick to traditional working hours',
      icon: 'clock' as const,
      isSelected: selectedMessagingWindow.value === V1SnapshotMessagingWindow.BUSINESS_HOURS,
      tooltipText: '',
      value: `${V1SnapshotMessagingWindow.BUSINESS_HOURS}`,
    },
  ];
});

// HANDLERS
const handleCommunicationToneSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1CommunicationTone = value;
};
const handlePushinessSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1Pushiness = value;
};
const handleThoroughnessSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1Thoroughness = value;
};
const handleMessagingWindowSelection = (value: string) => {
  copilotActivationService.projectCopilotConfigurationV1MessagingWindow = value;
};
</script>

<template>
  <ExpandableSection title="Prep Copilot on talking to candidates (Optional)">
    <!-- Copilot Name -->
    <p class="text-shade-88 mb-1 font-sans text-lg font-extrabold leading-[27px]">
      Name your Copilot
    </p>
    <p class="mb-4 block text-sm font-normal leading-[21px] text-shade-800">
      The AI will use this name when talking to candidates. Human names like "Shirley" or "Brian"
      work great!
    </p>
    <v-text-field
      v-model="copilotActivationService.projectCopilotConfigurationV1CopilotName"
      placeholder="Enter Copilot name"
      variant="outlined"
      density="compact"
      autofocus
      validate-on="submit"
      :rules="[]"
    />

    <!-- Communication Tone -->
    <MultipleSelectionItems
      title="What tone should Copilot use with candidates?"
      :items="communicationToneItems"
      class="mb-8"
      @click:select="handleCommunicationToneSelection"
    />

    <!-- Pushiness -->
    <MultipleSelectionItems
      title="How persistent should Copilot be with hesitant candidates?"
      :items="pushinessItems"
      class="mb-8"
      @click:select="handlePushinessSelection"
    />

    <!-- Thoroughness -->
    <MultipleSelectionItems
      title="How thorough should Copilot be when screening candidates?"
      :items="thoroughnessItems"
      class="mb-8"
      @click:select="handleThoroughnessSelection"
    />

    <!-- Messaging Window -->
    <MultipleSelectionItems
      title="When should Copilot message with candidates?"
      :items="messagingWindowItems"
      class="mb-8"
      @click:select="handleMessagingWindowSelection"
    />

    <!-- Selling Points -->
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1SellingPoints"
      title="What would your best recruiter tell candidates to sell them on the opportunity?"
      :placeholder="`Enter key selling points`"
    />

    <!-- Perks and Programs -->
    <SuggestionTextArea
      v-model="copilotActivationService.projectCopilotConfigurationV1PerksAndPrograms"
      title="What perks and programs make this opportunity different?"
      :placeholder="`Enter unique perks and programs`"
    />
  </ExpandableSection>
</template>

<style lang="scss" scoped>
.v-input :deep(.v-field),
.v-input--horizontal :deep(.v-field) {
  border-radius: 6px;

  .v-combobox__selection {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }

  .v-field__outline {
    @apply text-tint-80;
  }
  input {
    @apply font-sans text-sm font-normal leading-[21px] text-shade-880;
  }
  input::placeholder {
    @apply text-shade-800;
    opacity: 1;
  }

  &:hover {
    .v-field__outline {
      @apply text-shade-840;
    }
  }
}

.v-input :deep(.v-field.v-field--focused),
.v-input--horizontal :deep(.v-field.v-field--focused) {
  .v-field__outline {
    @apply text-highlight-500;
  }
}

.v-input :deep(.v-field.v-field--error),
.v-input--horizontal :deep(.v-field.v-field--error) {
  .v-field__outline {
    @apply text-critical-500;
  }
}
</style>
