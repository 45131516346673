<script setup lang="ts">
import { computed } from 'vue';

import Divider from '@/components/Shared/Divider/Divider.vue';

const props = withDefaults(
  defineProps<{ title?: string; addDivider?: boolean; subtitle?: string }>(),
  {
    addDivider: true,
  },
);

const displayingTitle = computed(() => {
  return props.title;
});

const displayingSubtitle = computed(() => {
  return props.subtitle;
});
</script>

<template>
  <div>
    <h3
      v-if="title"
      class="font-sans text-2xl font-bold leading-9 text-black"
      :class="{
        'mb-1': subtitle,
        'mb-4': !subtitle,
      }"
    >
      {{ displayingTitle }}
    </h3>
    <p v-if="subtitle" class="mb-6 font-sans text-base font-normal text-shade-800">
      {{ displayingSubtitle }}
    </p>
    <slot />

    <Divider class="my-10" v-if="addDivider" />
  </div>
</template>
