<script lang="ts" setup>
import { computed, ref } from 'vue';
import ChevronDownIcon from '@/assets/svg/chevron-down.svg?component';
import LightningPurpleIcon from '@/assets/svg/jobs/copilot-lightning-purple.svg?component';

const props = defineProps<{
  title: string;
}>();

const isOpen = ref(false);

const titleText = computed(() => props.title);
const sectionBgColor = computed(() =>
  !isOpen.value
    ? // eslint-disable-next-line max-len
      'linear-gradient(95.61deg, rgba(174, 53, 247, 0.04) 0%, rgba(255, 255, 255, 0.04) 8.1%, rgba(240, 236, 252, 0.04) 34.02%, rgba(108, 64, 229, 0.04) 100%)'
    : 'white',
);

const toggleSectionOpenState = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div>
    <div
      class="flex h-[59px] w-full cursor-pointer items-center justify-start rounded-t-lg border p-4"
      :style="{
        background: sectionBgColor,
      }"
      :class="{
        'rounded-b-lg border-[#D8BEFF]': !isOpen,
        'border-b-0 border-tint-80': isOpen,
      }"
      @click="toggleSectionOpenState"
    >
      <LightningPurpleIcon class="h-6 w-6" />
      <span class="font-sans text-lg font-extrabold leading-[27px]">
        {{ titleText }}
      </span>

      <ChevronDownIcon
        class="ml-auto h-6 w-6"
        :class="{
          'rotate-180': isOpen,
        }"
      />
    </div>

    <div v-show="isOpen" class="rounded-b-lg border-x border-b border-tint-80 px-4 pb-4">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
